<template>
  <div class="feedback-stars">
    <div
      class="feedback-stars__header"
      v-if="!$basil.isNil(title)">
      <h3 class="feedback-stars__title">{{ title }}</h3>
    </div>

    <div class="feedback-stars__body">
      <span
        :class="classes(star)"
        :key="star"
        @click="onSelect(star)"
        @mouseenter="onMouseEnter(star)"
        @mouseleave="onMouseLeave()"
        v-for="star in stars"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutFeedbackStars',

  props: {
    data: {
      type: Object,
      required: true
    },

    value: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      currentValue: 0
    }
  },

  computed: {
    displayedValue () {
      return this.currentValue || this.value
    },

    stars() {
      return this.$basil.get(this.data, 'number', 5)
    },

    title() {
      return this.$basil.get(this.data, 'title')
    }
  },

  methods: {
    classes(star) {
      return {
        'feedback-stars__star': true,
        'selected': star <= this.displayedValue
      }
    },

    onSelect(star) {
      this.$emit('input', star)
    },

    onMouseEnter(star) {
      this.currentValue = star
    },

    onMouseLeave() {
      this.currentValue = 0
    }
  },

  mounted() {
    this.currentValue = this.$basil.get(this, 'value', 0)
  }
}
</script>
