<template>
  <main>
    <div class="container -block">
      <div
        v-if="loading"
        class="container__loader">
        <ui-loader />
      </div>

      <div
        class="view-checkout-feedback__header"
        v-if="$basil.isNil(afterFeedback)">
        <h1 class="view-checkout-feedback__title">{{ title }}</h1>
        <h2 class="view-checkout-feedback__description">{{ description }}</h2>
      </div>

      <!-- Not submited -->
      <form
        class="view-checkout-feedback__body"
        v-if="$basil.isNil(afterFeedback)"
        @submit="onSubmit">
        <div
          class="view-checkout-feedback__question"
          :key="code"
          v-for="code in codes">
          <component
            :data="getData(code)"
            :is="getComponent(code)"
            v-model="values[code]"
          />
        </div>

        <actions-button
          :appearance="$pepper.Appearance.PRIMARY"
          class="view-checkout-feedback__submit"
          @click="submitFeedback"
        >{{ $t('resto.checkout_feedback_submit') }}</actions-button>
      </form>

      <!-- Submited -->
      <section
        class="view-checkout-feedback__body"
        v-if="!$basil.isNil(afterFeedback)">
        <div
          class="view-checkout-feedback__header"
          v-if="!alreadySubmitted">
          <h1 class="view-checkout-feedback__title">{{ this.$t('resto.checkout_feedback_thanks_title') }}</h1>
        </div>

        <p class="view-checkout-feedback__thanks">{{ afterFeedback }}</p>
      </section>
    </div>
  </main>
</template>

<script>
import MixinMultishop from '@/helpers/multishop'

import CheckoutFeedbackStars from '@/modules/checkout/components/feedback/stars'
import CheckoutFeedbackComment from '@/modules/checkout/components/feedback/comment'

export default {
  name: 'CheckoutFeedback',

  inject: ['$checkout'],

  mixins: [ MixinMultishop ],

  data() {
    return {
      data: null,
      values: null,
      loading: false,
      afterFeedback: null,
      alreadySubmitted: false,
    };
  },

  computed: {
    codes() {
      return Object.keys(this.$basil.get(this, 'values', {}) || {});
    },

    description() {
      return this.$basil.get(this.data, 'description', this.$t('resto.checkout_feedback_description'));
    },

    questions() {
      return this.$basil.get(this.data, 'questions', []);
    },

    title() {
      return this.$basil.get(this.data, 'title', this.$t('resto.checkout_feedback_title'));
    },
  },

  methods: {
    getComponent(code) {
      let question = this.$basil.get(this.data, 'questions', []).find(question => question.code === code);

      if(this.$basil.isNil(question)) {
        return;
      }

      if(question.type === 'stars') {
        return CheckoutFeedbackStars;
      }

      if(question.type === 'user_input' && question.input === 'textarea') {
        return CheckoutFeedbackComment;
      }
    },

    getData(code) {
      let question = this.$basil.get(this.data, 'questions', []).find(question => question.code === code);

      if(this.$basil.isNil(question)) {
        return;
      }

      return question;
    },

    onSubmit(e) {
      e.preventDefault()
    },

    submitFeedback() {
      this.loading = true

      this.$checkout.sendFeedback({ token: this.$route.params.token, feedback: this.values })
        .then((data) => this.afterFeedback = data.output)
        .catch((e) => {
          $console.error(e)
          if(e.response.status === 423) {
            this.alreadySubmitted = true
            this.afterFeedback = this.$t('resto.checkout_feedback_already_submitted')
          }
        })
        .finally(() => this.loading = false)
    }
  },

  mounted() {
    this.loading = true

    this.$checkout.getFeedback({ token: this.$route.params.token })
      .then(data => {
        this.data = data
        let values = {}

        this.questions.forEach(question => {
          if(question.type === 'stars') {
            values[question.code] = this.$basil.get(question, 'default', 0)
          }

          if(question.type === 'user_input' && question.input === 'textarea') {
            values[question.code] = this.$basil.get(question, 'default', '')
          }
        })

        this.values = values
      })
      .catch((e) => this.$router.push('/'))
      .finally(() => this.loading = false)
  }
}
</script>
