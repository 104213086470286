<template>
  <div class="feedback-comment flow">
    <div
      class="feedback-comment__header"
      v-if="!$basil.isNil(title)">
      <h3 class="feedback-comment__title">{{ title }}</h3>
    </div>

    <div class="feedback-comment__body">
      <forms-textarea
        :placeholder="$t('resto.feedback_textarea_placeholder')"
        v-model="currentValue"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutFeedbackComment',

  props: {
    data: {
      type: Object,
      required: true
    },

    value: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      currentValue: ''
    }
  },

  watch: {
    currentValue(newValue) {
      this.$emit('input', newValue)
    },
  },

  computed: {
    title() {
      return this.$basil.get(this.data, 'title')
    },
  },

  mounted() {
    this.currentValue = this.value
  },
}
</script>
